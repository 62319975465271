<template>
  <div class="djs_box">
    <div class="ridns">
      {{ djsData }}
    </div>
    <div class="sqidn">请稍等......</div>
  </div>
</template>

<script>
import { FaceIdentityResult } from "@/request/api";
export default {
  data() {
    return {
      djsData: "5",
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let that = this;
      var clear = setInterval(function () {
        console.log(that.djsData);
        if (that.djsData == "1") {
          that.FaceIdentit();
          clearInterval(clear);
        } else {
          // console.log(that.djsData);
          that.djsData = that.djsData - 1;
          console.log(that.djsData);
        }
      }, 1000);
    },
    FaceIdentit() {
      FaceIdentityResult().then((res) => {
        console.log(res);
        // alert(JSON.stringify(res));
        if (res.data.status == 1) {
          this.$notify({
            type: "success",
            message: "认证成功",
          });
          this.$router.push("/home");
        } else {
          this.$notify({
            type: "warning",
            message: "认证失败，请重新认证",
          });
          this.$router.push("/autonymAt");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.djs_box {
  width: 100%;
  position: relative;
  .ridns {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8rem;
    height: 8rem;
    margin-top: 14rem;
    margin-left: -4rem;
    border-radius: 50%;
    background: #1989fa;
    color: #ffffff;
    font-size: 3rem;
    text-align: center;
    line-height: 8rem;
  }
  .sqidn {
    position: fixed;
    bottom: 10%;
    left: 40%;
    font-size: 1rem;
    color: #1989fa;
  }
}
</style>